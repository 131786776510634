.App-header {
    background-color: transparent;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.App-header h1 {
    margin-bottom: 0px;
}

.App-header h2 {
    color: rgb(199, 199, 199);
    font-size: 18px;
    margin-top: 0px;
}