.item-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.item-page .add-reccomendation-button {
    height: 60px;
    background-color: rgb(0, 0, 0);
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 32px;
    width: calc(100% - 32px);
    max-width: 700px;
    height: 60px;
}

.item-page .add-reccomendation-button:hover {
    background-color: rgb(60, 60, 60);
}

.item-page .similar-items-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}