.similar-item-card {
    background-color: white;
    display: flex;
    flex-direction: row;
    max-height: 200px;
    margin-bottom: 16px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.12); 
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.12);
    width: 100%;
}

.similar-item-card .title {
    color: black;
}

.similar-item-card .item-image {
    object-fit: contain;
    width: 100px;
}

.similar-item-card .similarities {
    white-space: pre-line;
}

.similar-item-card .info {
    flex-direction: column;
    display: flex;
    align-items: center;
    flex: 1
}

.similar-item-card .add-button {
    color: black;
}

.similar-item-card .add-button:hover {
    background-color: rgb(212, 212, 212);
}

@media screen and (min-width: 700px) {
    .similar-item-card {
        border-radius: 8px;
        width: 700px;
    }

    .similar-item-card .item-image {
        object-fit: cover;
        width: 150px;
    }
}