.item-card {
    background-color: white;
    display: flex;
    flex-direction: row;
}

.item-card .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.item-card h1 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.item-card p {
    margin-bottom: 4px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 0px;
}

.item-card .source-link {
    color: rgb(128, 128, 128);
    margin-bottom: 16px;
}

.item-card img {
    max-width: 100px;
    object-fit: contain;
}

@media screen and (min-width: 700px) {
    .item-card {
        border-radius: 8px;
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.12); 
        box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.12);
    }

    .item-card img {
        max-width: 150px;
    }
}