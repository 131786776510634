.site-width {
    width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto Condensed', sans-serif;
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: 18px;
}

@media screen and (min-width: 700px) {
    .site-width {
        max-width: 700px;
    }
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 20px;
    }    
}